import './App.scss';
import { Routes,Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import MyTeam from './components/MyTeam';
import Skills from './components/Skills';


function App() {
  return (
   <>
   <Routes>
    <Route path="/" element={<Layout />} >
    <Route index element={<Home />} />
    <Route path="about" element={<About />} />
    <Route path="contact" element={<Contact />} />
    <Route path="testimonials" element={<Testimonials />} />
    <Route path="myteam" element={<MyTeam />} />
    <Route path="skills" element={<Skills />} />
    </Route>
   </Routes>
   </>
  );
}

export default App;
