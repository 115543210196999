import Loader from 'react-loaders'
import  './index.scss'
import AnimatedLetters from '../AnimatedLetters';
import { useState } from 'react';

const  Skills  = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    return (
    <>
       <div className="container contact-page">
        <div className="text-zone">
           <h1>
               <AnimatedLetters
               letterClass={letterClass}
                strArray={['S','k','i','l','l','s']} 
                 idx={[15]}               
               />
           </h1>
           <p>
            Expert in front-end development including
            <br />
            technologies like HTML5,CSS3,JavaScript,
            <br />
            React, TypeScript
            <br />
            <br />
            I'm not a designer but I have a good sense of aesthetics, &nbsp;
            <br />
            and experience in responsive
           </p>
        </div>
       </div>
       <Loader type="pacman" />
    </>)
}

export default Skills