import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState } from 'react'

const MyTeam = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    return (
    <>
         <div className='container myteam-page'>
              <div className='text-zone'>
                <h1>
                    <AnimatedLetters 
                    letterClass={letterClass}
                    strArray={['M','y',' ','T','e','a','m']} 
                        idx={15}
                    />
                </h1>
                <p>
                   <b> Michael Obeng </b> : &nbsp; <b>Web Developer</b>
                   <br />
                   <b> Quarm Enock </b> : &nbsp; <b>Grapgic Designer</b>
                   <br />
                   <b> Anane Obed Owusu </b> : &nbsp; <b>Software Engineer</b>
                   <br />
                   <b> Boah Samuel </b> : &nbsp; <b>Database Administrator</b>
                   <br />
                   <b> Adu Isaac </b> : &nbsp; <b>UserInterface Designer</b>
                   <br />
                   <b> Fobiri Kwame Solomon </b> : &nbsp; <b>Frontend Developer</b>
                   <br />
                   <b> Janet Adoma Amponsah </b> : &nbsp; <b>Backend Developer</b>
                   <br />
                   <b> Oduro Agnes </b> : &nbsp; <b>Web Developer</b>
                </p>
                </div>
                </div>  
         <Loader type="pacman" />
    
    </>)
}

export default MyTeam
