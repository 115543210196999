import './index.scss'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters';
import { useState } from 'react';

const Testimonials = () => {
    const [letterClass, setLetterClass] = useState('text-animate')


    return (
    <>
          <div className='container testimonials-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters 
                      letterClass={letterClass}
                    strArray={['T','e','s','t','i','m','o','n','i','a','l','s']}
                       idx={15} 
                    />
                </h1>
                <p>
                    Michael is the best web developer I have
                    ever met . He has been good to us since
                    day one. He has all the ideas Technology. &nbsp; <u> <b><i>Mr. Wilson</i></b> </u>
                </p>
                <p>
                    You are such a great web developer Michael,
                    <br />
                    you can never be replace by anyone in my business. &nbsp; <u> <b><i>Madam Akweley</i></b> </u>
                </p>
                <p>
                    At first I was doubting but now I'm believing.
                    <br />
                    Michael, you've  brought joy to my company. &nbsp; <u> <b><i>Mr. Marfo</i></b> </u>
                </p>
            </div>
          </div>
          <Loader type="pacman" />
    </>)
}

export default Testimonials