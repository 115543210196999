import { Link, NavLink } from 'react-router-dom';
import './index.scss';
import LogoS from '../../assets/images/logo-s.png';
import LogoSubtitle from '../../assets/images/logo_sub.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBookmark, faClose, faCog, faEnvelope, faHome, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGithub,  faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';

const Sidebar = () => {
    const [showNav, setShowNav] = useState(false)


    return ( 
    <div className='nav-bar'>
         <Link className='logo' to="/">
            <img src={LogoS} alt="logo" />
            <img className="sub-logo" src={LogoSubtitle} alt="slobodan" />
         </Link>
         <nav className={showNav ? 'mobile-show' : ''}>
            <NavLink 
                 exact="true" activeclassname="active" to="/"
                 onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
            </NavLink>
            <NavLink  activeclassname="active" className="about-link" to="/about"
                 onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
            </NavLink>
            <NavLink   activeclassname="active" className="contact-link" to="/contact"
                 onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
            </NavLink>
            <NavLink   activeclassname="active" className="testimonials-link" to="/testimonials"
                 onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faBookmark} color="#4d4d4e" />
            </NavLink>
            <NavLink   activeclassname="active" className="myteam-link" to="/myteam"
                 onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faUsers} color="#4d4d4e" />
            </NavLink>
            <NavLink   activeclassname="active" className="skills-link" to="/skills"
                 onClick={() => setShowNav(false)}>
                <FontAwesomeIcon icon={faCog} color="#4d4d4e" />
            </NavLink>
            <FontAwesomeIcon
               onClick={() => setShowNav(false)} 
               icon={faClose}
               color="#ffd700"
               size="3x"
               className='close-icon'
             />
         </nav>
         <ul>
            <li>
                <a target="_blank" rel='noreferrer' href="https://github.com/Mike13stf">
                    <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel='noreferrer' href="https://www.youtube.com/channel/UCUcOegu992PlyKdLWGQbg3w">
                    <FontAwesomeIcon icon={faYoutube} color="#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel='noreferrer' href="https://web.facebook.com/profile.php?id=100038471350353">
                    <FontAwesomeIcon icon={faFacebook} color="#4d4d4e" />
                </a>
            </li>
         </ul>
         <FontAwesomeIcon 
           onClick={() => setShowNav(true)}
           icon={faBars}
           color="#ffd700"
           size="3x"
           className='hamburger-icon'
         />
    </div>
  )
}

export default Sidebar;